.user {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header {
    display: flex;
    align-items: center;
    gap: 20px;

    &__text {
      display: flex;
      align-items: center;
      gap: 10px;

      &__heading {
        color: gray;
        margin: 0;
      }
    }
  }

  .content {
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .actions {
      max-width: 100%;
      padding: 0 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &__button:not(:first-child) {
        margin-left: 24px;
      }
    }
  }

  .tableContainer {
    border-radius: 10px;

    .table {
      max-height: 500px;

      th {
        font-size: 16px;
      }
    }
  }
}

.date {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  &__experience {
    color: gray;
  }
}

.actions {
  max-width: 50px;
}

.tableRow {
  cursor: pointer;
}

.select {
  width: 120px;
}
