.actions {
  text-align: center;
}

.cell {
  .banned {
    color: #b1b1b1;
  }
}

.tableRow {
  cursor: pointer;

  .bannedRow {
    background: #f4f4f4;
  }
}

.searchInput {
  max-width: 250px;
  width: 100%;
}
