.price {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;

  &__emoji {
    font-size: 16px;
  }
}

.description {
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
