.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    flex: 0 1 auto;

    .heading {
      color: gray;
    }
  }

  .body {
    max-height: calc(100vh - 65px - 48px);
    overflow: hidden;
    flex: 1 1 auto;
    padding: 16px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    table th {
      font-size: 16px;
      word-wrap: break-word;
    }

    .tableHeader {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 20px;
    }
  }
}
