// @use '/src/components/design-system/common/colors';

.order {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header {
    display: flex;
    align-items: center;
    gap: 20px;

    .heading {
      color: gray;
      margin: 0;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .content {
      border-radius: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.row {
  display: grid;
  grid-template-columns: 150px 1fr;
}
