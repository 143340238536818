.markdown {
  width: max-content;

  &__example {
    max-width: 280px;
  }

  &__line {
    white-space: nowrap;
  }
}