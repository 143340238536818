.tableContent {
  flex: 1 1 auto;
  flex-shrink: 0;

  .table th {
    font-size: 16px;
  }
}

.comment {
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
