.draggable {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px;

  &.isOver {
    background: rgba(0, 0, 0, 0.3);
  }
}
