.tableRow {
  cursor: pointer;
}

.select {
  width: 120px;
}

.comment {
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
